.waveform-container {
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;

  .waveform-object {
    transition: transform 0.15s ease;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-block;
  }

  .waveform-visualizer {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto 0;
    display: block;
    height: 125px;
    width: 100vw;
    left: 30px;
  }

  .waveform-controls {
    #play-button {
      position: fixed;
      bottom: 40px;
      right: 40px;
      border: none;
      background-color: #88b04b;
      padding: 1rem;
      font-weight: 800;
      border-radius: 50%;
      color: white;
      width: 60px;
      height: 60px;
      cursor: pointer;
      span {
        @extend .accessible-hide;
      }
      &.play {
        &:before {
          background-image: url("../../src/assets/images/ui/play.svg");
        }
      }

      &.pause {
        &:before {
          background-image: url("../../src/assets/images/ui/pause.svg");
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}
