/* The switch - the box around the slider */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 218px;
  height: 34px;
  margin-top: 2rem;
}

/* The slider */
.toggle-track {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-track:before {
  content: "low";
  position: absolute;
  height: 26px;
  width: 109px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  color: $grey800;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 26px;
}

.toggle-switch.high .toggle-track:before {
  content: "high";
  -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
}
