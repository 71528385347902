@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap");
@import "../node_modules/aviary-tokens/dist/tokens/scss/core-light-colors.scss";

@import "styles/AddFrame.scss";
@import "styles/Sidebar.scss";
@import "styles/Waveform.scss";
@import "styles/MusicToggle.scss";

* {
  font-family: Mulish, sans-serif;
}

.App {
  text-align: center;
  background-color: #222222;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 2;
  position: relative;
}

.App-link {
  color: #61dafb;
}

.background {
  transition: opacity 1s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
  opacity: 1;
  &.background-active {
    opacity: 1;
  }
  &.background-next {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0 !important;
  }
}

.content {
  margin-left: 250px;
  padding: 20px;
}

.accessible-hide {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
