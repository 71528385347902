.sidebar-nav {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100vh;
  background-color: rgba($grey800, 0.9);
  color: #fff;
  transition: all 0.3s ease;
  z-index: 10;
  padding: 2rem 1rem;

  .add-frame-button {
    transition: background-color 0.3s ease;
    border: 2px solid white;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    display: block;
    width: 100%;
    background-color: transparent;
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: $grey800;
    }
  }
}

.sidebar.open {
  .sidebar-nav {
    left: 0;
  }
  .toggle-button {
    span:not(.accessible-hide) {
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(45deg);
      }
      &:nth-child(4) {
        transform: rotate(-45deg);
      }
      &:nth-child(5) {
        opacity: 0;
      }
    }
  }
}

.toggle-button {
  transition: left 0.3s ease;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  outline: none;
  z-index: 10;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  z-index: 11;
  span:not(.accessible-hide) {
    transition: transform 0.3s ease, opacity 0.3s ease;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: white;
    display: inline-block;
    position: absolute;
    left: 0;
    &:nth-child(2) {
      top: 0;
    }
    &:nth-child(3) {
      top: 9px;
    }
    &:nth-child(4) {
      top: 9px;
    }
    &:nth-child(5) {
      top: 18px;
    }
  }
}
