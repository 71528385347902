@import "../../node_modules/aviary-tokens/dist/tokens/scss/core-light-colors.scss";

.frame-builder {
  margin: 2rem auto;
}
.frame-popup {
  position: fixed;
  z-index: 11;
  width: 400px;
  height: 300px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  background-color: #2e3a47;
  border-radius: 8px;
  padding: 3rem;

  &:after {
    content: "";
    position: fixed;
    z-index: -1;
    background-color: rgba($grey800, 0.75);
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .frame-popup-close {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0rem;
    right: 0rem;
    padding: 1rem;
    img {
      transition: transform 0.3s ease;
      display: inline-block;
      width: 1rem;
      height: 1rem;
    }
    &:hover {
      img {
        transform: rotate(180deg);
      }
    }
    span {
      @extend .accessible-hide;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    label {
      @extend .accessible-hide;
    }
    input {
      width: 100%;
      font-size: 1.25rem;
      padding: 0.5rem;
      margin-bottom: 1rem;
    }
  }
}

.frame-preview {
  width: 100%;
  border: 1px solid $grey100;
  padding: 1rem;
  display: block;
  margin-bottom: 1rem;
  position: relative;

  p {
    font-size: 1rem;
  }

  h1 {
    font-size: 1.25rem;
  }
  img {
    max-width: 100%;
    display: inline-block;
  }

  &:hover {
    .drag-handle,
    .remove-button {
      opacity: 1;
    }
  }

  .remove-button {
    transition: opacity 0.3s ease;
    cursor: pointer;
    user-select: none;
    opacity: 0;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
    img {
      width: 12px;
      height: 12px;
    }
  }

  .drag-handle {
    transition: opacity 0.3s ease;
    cursor: move;
    user-select: none;
    opacity: 0;

    img {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      width: 12px;
      height: auto;
    }
  }
}

.frame-object {
  transition: opacity 0.5s ease;
  color: $grey100;
  opacity: 1;
  position: fixed;
  width: 50vw;
  height: 50vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
  }
  h1 {
    font-size: 4.5rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
  }
  img {
    max-width: 90%;
    max-height: 90%;
  }
  &:not(.is-active) {
    opacity: 0;
  }
}
